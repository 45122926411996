<template>
  <div class="left-section">
    <div style="padding: 20px">
      <div
        style="font-family:'Poppins-Medium'; margin-bottom: 20px; margin-top: 20px;"
      >
        Filter By
      </div>
      <v-text-field
        hide-details
        label="Search"
        solo
        dense
        outlined
        full-width
        append-icon="mdi-magnify"
        v-model="keyword"
      />
      <div class="mt-5 mb-3 d-flex flex-row">
        <div
          :class="{
            'd-flex': true,
            'align-center': true,
            'justify-center': true,
            'pa-1': true,
            'no-select': true,
            'section-week': true,
            'section-selected': selectedSection == 0,
          }"
          @click="onSelectSection(0)"
        >
          Week
        </div>
        <div
          :class="{
            'd-flex': true,
            'align-center': true,
            'justify-center': true,
            'pa-1': true,
            'no-select': true,
            'section-month': true,
            'section-selected': selectedSection == 1,
          }"
          @click="onSelectSection(1)"
        >
          Month
        </div>
        <div
          :class="{
            'd-flex': true,
            'align-center': true,
            'justify-center': true,
            'pa-1': true,
            'no-select': true,
            'section-year': true,
            'section-selected': selectedSection == 2,
          }"
          @click="onSelectSection(2)"
        >
          Year
        </div>
      </div>
      <div class="d-flex flex-row" style="justify-content: space-between">
        <label style="margin-top: 10px">From: </label>
        <div style="width: 120px">
          <v-menu
            ref="fromDateTimer"
            v-model="fromDateTimer"
            :close-on-content-click="false"
            :return-value.sync="fromDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromDateStr"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                filled
                background-color="white"
                hide-details
              />
            </template>
            <v-date-picker v-model="fromDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="fromDateTimer = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="onSaveFromDate(fromDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </div>
      <div class="d-flex flex-row mt-1" style="justify-content: space-between">
        <label style="margin-top: 10px">To: </label>
        <div style="width: 120px">
          <v-menu
            ref="toDateTimer"
            v-model="toDateTimer"
            :close-on-content-click="false"
            :return-value.sync="toDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toDateStr"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                filled
                background-color="white"
                hide-details
              />
            </template>
            <v-date-picker v-model="toDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="toDateTimer = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="onSaveToDate(toDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </div>
      <div
        style="font-family:'Poppins-Medium'; margin-bottom: 20px; margin-top: 20px;"
      >
        Freezer Manager
      </div>
      <div>
        <v-autocomplete
          :items="freezers"
          dense
          outlined
          :filter="freezerFilter"
          @change="onSelectedFreezer"
          hide-details
          v-model="selectedFreezer"
          clearable
          @click:clear="onSelectedFreezer"
        >
          <template v-slot:selection="data">
            {{ data.item.first_name + " " + data.item.last_name }}
          </template>
          <template v-slot:item="data">
            <v-list-item-avatar>
              <editable-avatar
                :image="data.item.image"
                :size="40"
                :editable="false"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                v-html="data.item.first_name + ' ' + data.item.last_name"
              />
              <v-list-item-subtitle v-html="data.item.phone" />
              <v-list-item-subtitle v-html="data.item.email" />
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </div>
      <div
        style="font-family:'Poppins-Medium'; margin-bottom: 20px; margin-top: 20px;"
      >
        Client
      </div>
      <div>
        <v-autocomplete
          :items="clients"
          dense
          outlined
          :filter="clientFilter"
          @change="onSelectedClient"
          v-model="selectedClient"
          clearable
          hide-details
          @click:clear="onSelectedClient"
        >
          <template v-slot:selection="data">
            {{
              data.item.first_name +
                " " +
                (data.item.last_name ? data.item.last_name : "")
            }}
          </template>
          <template v-slot:item="data">
            <v-list-item-avatar>
              <editable-avatar
                :image="data.item.image"
                :size="40"
                :editable="false"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                v-html="
                  data.item.first_name +
                    ' ' +
                    (data.item.last_name ? data.item.last_name : '')
                "
              />
              <v-list-item-subtitle v-html="data.item.phone" />
              <v-list-item-subtitle v-html="data.item.email" />
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </div>
      <div
        style="font-family:'Poppins-Medium'; margin-bottom: 20px; margin-top: 20px; display: none;"
      >
        Address
      </div>
      <div style="display:none">
        <v-text-field
          dense
          hide-details
          outlined
          @input="onSelectedAddress"
          v-model="address"
          clearable
          @click:clear="onSelectedAddress"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import dateFormat from "dateformat";
import EditableAvatar from "./core/EditableAvatar.vue";
export default {
  components: { EditableAvatar },
  data() {
    return {
      fromDateTimer: false,
      fromDate: null,
      fromDateStr: "",
      toDateTimer: false,
      toDate: null,
      toDateStr: "",
      selectedSection: 0,
      selectedFreezer: null,
      selectedClient: null,
      address: null,
    };
  },
  computed: {
    ...mapGetters({
      intakes: "intake/getAllIntakes",
      allQuestions: "question/getAllQuestions",
      clients: "client/getAllClients",
      freezers: "freezer/getAllFreezers",
    }),
    keyword: {
      get() {
        return this.$store.state.auth.keyword;
      },
      set(value) {
        this.$store.commit("auth/setKeyword", value);
      },
    },
  },
  methods: {
    ...mapActions({
      fetchAllClients: "client/fetchAllClients",
      fetchAllFreezers: "freezer/fetchAllFreezers",
    }),
    onSaveFromDate(dateStr) {
      var date = Date.parse(dateStr + " EDT");
      this.fromDateStr = dateFormat(date, "mm/dd/yyyy");
      this.fromDateTimer = false;
      this.selectedSection = -1;
      if (this.$route.query.start_date != this.fromDateStr) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            section: null,
            start_date: this.fromDateStr,
          },
        });
      }
    },
    onSaveToDate(dateStr) {
      var date = Date.parse(dateStr + " EDT");
      this.toDateStr = dateFormat(date, "mm/dd/yyyy");
      this.toDateTimer = false;
      this.selectedSection = -1;
      if (this.$route.query.end_date != this.toDateStr) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            section: null,
            end_date: this.toDateStr,
          },
        });
      }
    },
    checkQueryParams() {
      if (this.clients && this.clients.length && this.$route.query.to) {
        this.clients.map((c) => {
          if (c.client_id == this.$route.query.to) {
            this.selectedClient = c;
          }
        });
      }
      if (this.freezers && this.freezers.length && this.$route.query.from) {
        this.freezers.map((p) => {
          if (p._id == this.$route.query.from) {
            this.selectedFreezer = p;
          }
        });
      }
      if (this.$route.query.start_date) {
        this.fromDateStr = this.$route.query.start_date;
      }
      if (this.$route.query.end_date) {
        this.toDateStr = this.$route.query.end_date;
      }
      if (this.$route.query.section != undefined) {
        this.onSelectSection(this.$route.query.section);
      } else if (this.$route.query.start_date || this.$route.query.end_date) {
        this.selectedSection = -1;
      }

      if (this.$route.query.address) {
        this.address = this.$route.query.address;
      }
    },
    onSelectedFreezer(item) {
      this.selectedFreezer = item;
      if (this.$route.query.from != item._id) {
        this.$router.replace({
          query: { ...this.$route.query, from: item._id },
        });
      }
    },
    onSelectedClient(item) {
      this.selectedClient = item;
      if (!item) {
        this.$router.replace({
          query: { ...this.$route.query, to: null },
        });
      } else if (this.$route.query.to != item.client_id) {
        this.$router.replace({
          query: { ...this.$route.query, to: item.client_id },
        });
      }
    },
    freezerFilter(item, queryText) {
      const searchText = queryText.toLowerCase();
      if (
        Object.keys(item).some(
          (k) => item[k] && `${item[k]}`.toLowerCase().includes(searchText)
        )
      ) {
        return true;
      }
      if (`{item.first_name + item.last_name}`.includes(searchText)) {
        return true;
      }
      return false;
    },
    clientFilter(item, queryText) {
      const searchText = queryText.toLowerCase();
      return Object.keys(item).some(
        (k) => item[k] && `${item[k]}`.toLowerCase().includes(searchText)
      );
    },
    onSelectSection(index) {
      this.selectedSection = index;
      var date = new Date();
      this.toDateStr = dateFormat(date, "mm/dd/yyyy");
      if (this.selectedSection == 0) {
        date.setDate(date.getDate() - 7);
      } else if (this.selectedSection == 1) {
        date.setDate(date.getDate() - 30);
      } else if (this.selectedSection == 2) {
        date.setDate(date.getDate() - 365);
      }
      this.fromDateStr = dateFormat(date, "mm/dd/yyyy");
      if (this.$route.query.section != index) {
        this.$router.replace({
          query: { ...this.$route.query, section: index },
        });
      }
    },
    onSelectedAddress() {
      if (this.$route.query.address != this.address) {
        this.$router.replace({
          query: { ...this.$route.query, address: this.address },
        });
      }
    },
  },
  created() {
    this.loading = true;
    this.fetchAllClients()
      .then(() => {
        this.fetchAllFreezers()
          .then(() => {
            this.loading = false;
            this.checkQueryParams();
          })
          .catch((error) => {
            this.loading = false;
            if (error.response != undefined && error.response.status === 401) {
              this.tokenDialog = true;
            } else {
              console.log(error);
            }
          });
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
    var date = new Date();
    this.toDateStr = dateFormat(date, "mm/dd/yyyy");
    date.setDate(date.getDate() - 7);
    this.fromDateStr = dateFormat(date, "mm/dd/yyyy");
    this.checkQueryParams();
  },
};
</script>
<style scoped lang="css">
.left-section {
  max-height: 0;
  overflow-y: hidden;
  position: sticky;
  min-height: inherit;
  overflow-x: hidden;
  top: 70px;
  max-width: 320px;
  min-width: 320px;
  width: 320px;
  background: white;
}
.left-section:hover {
  overflow-y: auto;
}
.left-section::-webkit-scrollbar {
  width: 8px;
  transition-duration: 0.5s;
}

/* Track */
.left-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.left-section::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.left-section::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

.section-week {
  width: 100%;
  border-style: solid;
  border-color: #7024c4;
  border-width: 1px;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
}
.section-week:hover {
  background: #f4f9ff;
}
.section-month {
  width: 100%;
  border-style: solid none;
  border-color: #7024c4;
  border-width: 1px;
  cursor: pointer;
}
.section-month:hover {
  background: #f4f9ff;
}
.section-year {
  width: 100%;
  border-style: solid;
  border-color: #7024c4;
  border-width: 1px;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}
.section-year:hover {
  background: #f4f9ff;
}
.section-selected {
  background: #f3e9ff;
}

.v-text-field--outlined >>> fieldset {
  border-color: #f3e9ff;
}
</style>
